import * as React from "react";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Grid";

import ucwords from "@/features/jobSearch/hooks/ucword";
import Box from "@mui/material/Box";

function Faq({ seoContent }) {
  const [expanded, setExpanded] = React.useState("panel0");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid
      container
      justifyContent="center"
      columnSpacing={{ xs: 0, md: 2 }}
      sx={{
        "& ul": {
          listStyle: "inside",
        },
        "& p": {
          m: 0,
        },
        "& i": {
          background: (theme) => theme.palette.info.main,
          py: 0.8,
          px: 0.8,
          fontSize: 14,
          borderRadius: "50%",
        },
        "& .MuiAccordion-root": {
          ":before": {
            all: "unset",
          },
          ":first-of-type": {
            borderRadius: 2,
          },
          ":last-of-type": {
            borderRadius: 2,
          },
          mb: 1,
          border: "1px solid #DEDEDF",
          background: "#FFFFFF ",
          borderRadius: 2,
        },
      }}
      mt={4}
    >
      <Grid item xs={12} md={10}>
        <Typography
          component="h3"
          fontSize={24}
          fontWeight={600}
          textAlign="center"
          mb={3}
          mt={3}
        >
          Frequently Asked Questions
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} mb={2}>
        {seoContent?.map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            defaultExpanded
            elevation={0}
          >
            <AccordionSummary
              expandIcon={
                expanded === `panel${index}` ? (
                  <i className="icon-minus" />
                ) : (
                  <i className="icon-add" />
                )
              }
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
            >
              <Typography component="span" fontSize={16} fontWeight={600}>
                {ucwords(item?.title.toLowerCase())}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                component="span"
                fontSize={14}
                fontWeight={400}
                dangerouslySetInnerHTML={{ __html: item?.description }}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
}

export default Faq;
